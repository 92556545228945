import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import Header from 'components/Landing/Header';
import BannerImage from 'components/Landing/BannerImage';

import './AuthLayout.less';
import 'components/Landing/static/style';

function AuthLayout({ children }) {
  return (
    <div className="auth-page">
      <Row type="flex" justify="center">
        <Col xs={24}>
          <Header key="header" style={{ backgroundColor: 'white' }} />
        </Col>
        <Col xs={22} md={18} lg={8}>
          <BannerImage />
        </Col>
        <Col xs={0} md={2} lg={1} />
        <Col
          xs={24}
          md={24}
          lg={6}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            className="form-container"
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {children}
          </div>
        </Col>
      </Row>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default AuthLayout;
