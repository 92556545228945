import React, { useCallback } from 'react';
import { Form, Input, Button, Checkbox } from 'antd';
import PropTypes from 'prop-types';
import QueueAnim from 'rc-queue-anim';
import SEO from 'components/SEO';
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import { signInLoadingSelector } from 'redux/Auth/selector';
import { signIn } from 'redux/Auth/slice';
import AuthLayout from 'components/Layout/AuthLayout';

const { Item } = Form;

function SignInPage({ location }) {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { from } = location.state || {};
  const handleFinish = useCallback(
    (values) => {
      dispatch(signIn({ ...values, from }));
    },
    [dispatch, from]
  );

  const loading = useSelector(signInLoadingSelector);

  return (
    <AuthLayout>
      <SEO title={t('signIn')} description={t('SEODescription')} />
      <QueueAnim
        className="auth-banner-content-wrapper"
        delay={300}
        ease="easeOutQuart"
      >
        <h1 key="1">{startCase(t('signIn'))}</h1>
        <Form key="2" onFinish={handleFinish} className="auth-form" form={form}>
          <Item
            name="email"
            rules={[{ required: true, message: t('requiredEmail') }]}
          >
            <Input addonBefore={t('email')} placeholder="johndoe@example.com" />
          </Item>
          <Item
            name="password"
            rules={[
              {
                required: true,
                message: t('requiredPassword'),
              },
            ]}
          >
            <Input.Password
              addonBefore={t('password')}
              placeholder="********"
            />
          </Item>
          <Item>
            <Item name="remember" valuePropName="checked" initialValue noStyle>
              <Checkbox>{t('rememberMe')}</Checkbox>
            </Item>
            <Link className="login-form-forgot" to="/auth/forgot-password">
              {t('forgotPassword')}
            </Link>
          </Item>
          <Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              size="large"
              loading={loading}
            >
              {t('signIn')}
            </Button>
            {t('dontHaveAccount')} <Link to="/auth/sign-up">{t('signUp')}</Link>
          </Item>
        </Form>
      </QueueAnim>
    </AuthLayout>
  );
}

SignInPage.propTypes = {
  location: PropTypes.object,
};

export default React.memo(SignInPage);
