export const emailSelector = (state) => state.auth.email;
export const loadingSelector = (state) => state.auth.loading;
export const forgotPasswordLoadingSelector = (state) =>
  state.auth.forgotPasswordLoading;
export const verifyPasswordLoadingSelector = (state) =>
  state.auth.verifyPasswordLoading;
export const signInLoadingSelector = (state) => state.auth.signInLoading;
export const signUpLoadingSelector = (state) => state.auth.signUpLoading;
export const verifyEmailLoadingSelector = (state) =>
  state.auth.verifyEmailLoading;
